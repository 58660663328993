import 'current-script-polyfill'; //IE11 polyfill
import { STORAGE_ENV_KEY_NAME, ENVS, CONF_COOKIE_NAME } from '../consts';
import getEnvFromUrl from './getEnvFromUrl';
export { default as replaceConfigEnvTemplate } from './replaceConfigEnvTemplate';

export function getAppEnv() {
  // eslint-disable-next-line no-undef
  return APP_ENV;
}

export function getAppConfig() {
  // eslint-disable-next-line no-undef
  return APP_CONFIG;
}

export function getNodeEnv() {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV;
}

export function getLocalStorageEnv() {
  try {
    return localStorage.getItem(STORAGE_ENV_KEY_NAME);
  } catch (e) {
    console.warn('env-config', 'Cannot read environment override from local storage');
  }
}

export function get(object, path, defaultVal) {
  const _path = Array.isArray(path) ? path : path.split('.').filter((i) => i.length);

  if (!_path.length) {
    return object;
  } else if (object === undefined) {
    return defaultVal;
  }

  return get(object[_path.shift()], _path, defaultVal);
}

export function getEnvTypeForNumberedEnvs(envName = '') {
  const INTERNAL_ENVS = [ENVS.STAGING, ENVS.NIGHTLY];

  return INTERNAL_ENVS.find((env) => envName.match(`-${env}\\d*`) || envName.match(`^${env}\\d*`));
}

export function getUrlEnv() {
  const envFromUrl = getEnvFromUrl();
  // if the envFromUrl is either equals to one of the env types or it's a numbered env e.g. staging7
  if (getEnvTypeForNumberedEnvs(envFromUrl) || Object.values(ENVS).find((env) => env === envFromUrl)) {
    return envFromUrl;
  }
}

export function getUrlConf() {
  const envFromUrl = getEnvFromUrl();
  // if the envFromUrl is either equals to one of the env types or it's a numbered env e.g. staging7
  return getEnvTypeForNumberedEnvs(envFromUrl) || Object.values(ENVS).find((env) => env === envFromUrl);
}

export function getCookie(cName) {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful

  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) {
      res = val.substring(name.length);
    }
  });
  return res;
}

function getConfCookieNameSuffix() {
  const scriptUrl = new URL(document.currentScript.src);

  if (document.location.hostname === scriptUrl.hostname) {
    return;
  }
  const regex = /(.*?)(\.cloudinary\.com)?$/;

  return (regex.exec(scriptUrl.hostname) || [])[1];
}

/*
for widgets, there is a script that's running on the customer's website
so we cannot set a cookie on the script's domain (script cannot read it)
in case the script origin is different from the browser origin, we are reading the cookie from the customer's website domain
and in order to differentiate between apps, we are giving it a suffix with the application name (subdomain)
*/
export const getConfCookieName = (appName = getConfCookieNameSuffix()) => [CONF_COOKIE_NAME, appName].filter(Boolean).join('-');
