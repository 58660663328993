import memoizeOne from 'memoize-one';
import { ENVS } from '../consts';
import replaceWithEnvVars from './replaceWithEnvVars';

const ENV_VALUES = {
  [ENVS.PRODUCTION]: '',
  [ENVS.DEVELOPMENT]: 'dev'
};

const doReplace = (envName, config) => {
  const replaceValue = ENV_VALUES[envName] !== undefined ? ENV_VALUES[envName] : envName;

  return replaceWithEnvVars(config, {
    ENV_NAME: replaceValue && replaceValue + '.',
    DASH_ENV_NAME: replaceValue && '-' + replaceValue,
  });
};

const replaceConfigEnvTemplate = memoizeOne(doReplace,
  ([newEnv], [prevEnv]) => {
    return newEnv === prevEnv;
  });

export default replaceConfigEnvTemplate;
