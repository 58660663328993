import { ENVS } from '../consts';

const cloudinaryDomainSuffix = '.cloudinary.com';
const envOnDemandSubdomainPrefix = 'eod4cld';

const getCloudinarySubDomain = () => {
  const hostname = window?.location?.hostname?.toLowerCase() || '';
  return hostname.endsWith(cloudinaryDomainSuffix) ? hostname.split('.')[0] : null;
};

const getEnvNameFromSubDomain = (subDomain) => {
  return subDomain.match(/([^-]+)$/)?.[1];
};

const getEnvNamesForValue = (value) => {
  //if the widget is running on env-on-demand subdomain, we should go to staging
  if (value.startsWith(envOnDemandSubdomainPrefix)) {
    return ENVS.STAGING;
  }

  return getEnvNameFromSubDomain(value);
};

/**
 * figures out the environment based on the URL currently loaded in
 * examples:
 *  https://www.cloudinary.com/ -> undefined
 *  https://staging.cloudinary.com -> staging
 *  https://something-staging14.cloudinary.com -> staging14
 *  https://kuku.cloudinary.com -> kuku
 */
const getEnvFromUrl = () => {
  const subDomain = getCloudinarySubDomain();

  if (subDomain) {
    return getEnvNamesForValue(subDomain);
  }
};

export default getEnvFromUrl;
